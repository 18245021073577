import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Hr, Section, Strong, Em } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				BlastZone
			</title>
			<meta name={"description"} content={"Відчуйте неймовірний адреналін!"} />
			<meta property={"og:title"} content={"BlastZone"} />
			<meta property={"og:description"} content={"Відчуйте неймовірний адреналін!"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-4.jpg?v=2024-06-12T07:08:52.451Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-4.jpg?v=2024-06-12T07:08:52.451Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-4.jpg?v=2024-06-12T07:08:52.451Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-4.jpg?v=2024-06-12T07:08:52.451Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-4.jpg?v=2024-06-12T07:08:52.451Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-4.jpg?v=2024-06-12T07:08:52.451Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-4.jpg?v=2024-06-12T07:08:52.451Z"} />
		</Helmet>
		<Components.Header />
		<Components.Marque />
		<Section padding="100px 0 100px 0" lg-padding="80px 0 80px 0" quarkly-title="Content-14">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					lg-width="100%"
					display="flex"
					flex-direction="row"
					justify-content="center"
					lg-margin="0px 0px 50px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-3.jpg?v=2024-06-12T07:08:52.477Z"
						display="block"
						object-fit="cover"
						height="100%"
						lg-width="100%"
						lg-height="700px"
						sm-height="300px"
						margin="0px 5px 0px 0px"
						md-height="500px"
						max-height="500px"
						srcSet="https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-3.jpg?v=2024-06-12T07%3A08%3A52.477Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-3.jpg?v=2024-06-12T07%3A08%3A52.477Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-3.jpg?v=2024-06-12T07%3A08%3A52.477Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-3.jpg?v=2024-06-12T07%3A08%3A52.477Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-3.jpg?v=2024-06-12T07%3A08%3A52.477Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-3.jpg?v=2024-06-12T07%3A08%3A52.477Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-3.jpg?v=2024-06-12T07%3A08%3A52.477Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					width="50%"
					padding="0px 80px 0px 0"
					justify-content="center"
					lg-padding="0px 0px 0px 0"
					lg-width="100%"
					order="-1"
					align-items="center"
				>
					<Text
						margin="0px 0px 20px 0px"
						font="--headline2"
						sm-font="normal 700 42px/1.2 --fontFamily-sans"
						color="--darkL1"
						lg-margin="0px 0px 35px 0px"
						text-align="center"
					>
						Які послуги ми надаємо?
					</Text>
					<Hr
						min-height="10px"
						margin="0px 0px 20px 0px"
						width="50px"
						display="flex"
						justify-content="center"
						border-width="2px 0 0 0"
						border-color="--color-darkL1"
					/>
					<Text margin="0px 0px 25px 0px" font="--base" color="--darkL1" text-align="center">
						У BlastZone Paintball ми пишаємося тим, що пропонуємо різноманітні пейнтбольні послуги, розроблені для того, щоб забезпечити захоплюючий досвід для кожного гравця. Незалежно від того, чи хочете ви відточити свої навички, відсвяткувати особливу подію чи просто повеселитися з друзями, наші заклади створені для того, щоб забезпечити незабутній і захоплюючий день.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="64px 0 64px 0" md-padding="36px 0 36px 0" lg-padding="48px 0 48px 0" quarkly-title="Images-20">
			<Override slot="SectionContent" max-width="100%" />
			<Box
				quarkly-title="Card"
				justify-content="space-between"
				sm-flex-direction="column"
				position="relative"
				lg-margin="0px 0px 64px 0px"
				sm-align-items="center"
				display="flex"
				align-items="center"
				margin="0px 0px 96px 0px"
			>
				<Image
					src="https://uploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-2.jpg?v=2024-06-12T07:08:52.465Z"
					object-fit="cover"
					width="45%"
					flex="0 0 auto"
					md-width="50%"
					sm-margin="0px 0px 24px 0px"
					srcSet="https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-2.jpg?v=2024-06-12T07%3A08%3A52.465Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-2.jpg?v=2024-06-12T07%3A08%3A52.465Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-2.jpg?v=2024-06-12T07%3A08%3A52.465Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-2.jpg?v=2024-06-12T07%3A08%3A52.465Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-2.jpg?v=2024-06-12T07%3A08%3A52.465Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-2.jpg?v=2024-06-12T07%3A08%3A52.465Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-2.jpg?v=2024-06-12T07%3A08%3A52.465Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Text
					sm-margin="0px 0px 0px 0px"
					sm-text-align="left"
					margin="0px 0px 0px 8%"
					max-width="450px"
					md-margin="0px 0px 0px 24px"
					font="--base"
				>
					Відкриті ігрові сеанси: пориньте в дію за допомогою наших сеансів гри. Ідеально підходить для окремих осіб або груп, які хочуть поєднатися з іншими гравцями.
Рівні змішаних навичок: чудово підходить як для новачків, так і для досвідчених гравців.
Гнучкий час: приєднуйтеся, коли вам зручно, із заняттями, які триватимуть протягом дня.
					<br />
					<br />
					<br />
					Молодіжні та шкільні заходи: безпечні, веселі та контрольовані заходи, призначені для молодших гравців.
Відповідне до віку обладнання: легше спорядження та менший вплив для молодших гравців.
Освітній фокус: наголос на командній роботі, лідерстві та спортивній майстерності.
				</Text>
			</Box>
			<Box
				align-items="center"
				sm-flex-direction="column-reverse"
				sm-align-items="center"
				quarkly-title="Card"
				display="flex"
			>
				<Box sm-margin="0px 0px 0px 0px" max-width="450px" margin="0px 8% 0px 0px" md-margin="0px 24px 0px 0px">
					<Text font="--base" sm-text-align="left">
						Приватні групові бронювання: бронюйте власний ігровий час для вечірок, командоутворення чи приватних матчів.
Особисті рефері: кожна група отримує спеціального співробітника, який керуватиме грою.
Настроювані сценарії: налаштуйте режим гри відповідно до вподобань вашої групи.
						<br />
						<br />
						<br />
						Тактичні тренування: вдосконалюйте свої навички під керівництвом наших досвідчених інструкторів.
Розвиток навичок: Зосередьтеся на стратегії, точності стрільби та командній роботі.
Розширені методи: вивчіть тактику, яка дає вам конкурентну перевагу.
					</Text>
				</Box>
				<Image
					md-width="50%"
					sm-margin="0px 0px 24px 0px"
					align-self="flex-end"
					src="https://uploads.quarkly.io/6668b0b2fdcae00021e34031/images/%20.jpeg?v=2024-06-12T07:08:52.450Z"
					object-fit="cover"
					width="45%"
					flex="0 0 auto"
					max-height="400px"
					srcSet="https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/%20.jpeg?v=2024-06-12T07%3A08%3A52.450Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/%20.jpeg?v=2024-06-12T07%3A08%3A52.450Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/%20.jpeg?v=2024-06-12T07%3A08%3A52.450Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/%20.jpeg?v=2024-06-12T07%3A08%3A52.450Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/%20.jpeg?v=2024-06-12T07%3A08%3A52.450Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/%20.jpeg?v=2024-06-12T07%3A08%3A52.450Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/%20.jpeg?v=2024-06-12T07%3A08%3A52.450Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="64px 0 64px 0" md-padding="36px 0 36px 0" lg-padding="48px 0 48px 0" quarkly-title="Images-20">
			<Override slot="SectionContent" max-width="100%" />
			<Box
				quarkly-title="Card"
				justify-content="space-between"
				sm-flex-direction="column"
				position="relative"
				lg-margin="0px 0px 64px 0px"
				sm-align-items="center"
				display="flex"
				align-items="center"
				margin="0px 0px 96px 0px"
			>
				<Image
					src="https://uploads.quarkly.io/6668b0b2fdcae00021e34031/images/2-1.jpg?v=2024-06-12T07:08:52.451Z"
					object-fit="cover"
					width="45%"
					flex="0 0 auto"
					md-width="50%"
					sm-margin="0px 0px 24px 0px"
					max-height="400px"
					srcSet="https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/2-1.jpg?v=2024-06-12T07%3A08%3A52.451Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/2-1.jpg?v=2024-06-12T07%3A08%3A52.451Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/2-1.jpg?v=2024-06-12T07%3A08%3A52.451Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/2-1.jpg?v=2024-06-12T07%3A08%3A52.451Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/2-1.jpg?v=2024-06-12T07%3A08%3A52.451Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/2-1.jpg?v=2024-06-12T07%3A08%3A52.451Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/2-1.jpg?v=2024-06-12T07%3A08%3A52.451Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Text
					sm-margin="0px 0px 0px 0px"
					sm-text-align="left"
					margin="0px 0px 0px 8%"
					max-width="450px"
					md-margin="0px 0px 0px 24px"
					font="--base"
				>
					Спеціальні події та турніри: беріть участь у наших захоплюючих турнірах або приєднуйтеся до спеціальних тематичних заходів.
Змагальна гра: ідеально підходить для тих, хто хоче перевірити свої навички проти кращих.
Унікальні теми: Регулярно заплановані події з унікальним ігровим процесом і призами.
				</Text>
			</Box>
			<Box
				align-items="center"
				sm-flex-direction="column-reverse"
				sm-align-items="center"
				quarkly-title="Card"
				display="flex"
			>
				<Box sm-margin="0px 0px 0px 0px" max-width="450px" margin="0px 8% 0px 0px" md-margin="0px 24px 0px 0px">
					<Text font="--base" sm-text-align="left">
						<Em>
							<Strong>
								Більше, ніж просто ігри{"\n"}
								<br />
							</Strong>
						</Em>
						<br />
						Ажіотаж у BlastZone не обмежується лише іграми. Ми пропонуємо повний спектр послуг, щоб покращити ваш досвід пейнтболу. Ми тут, щоб зробити ваші пейнтбольні пригоди максимально приємними та доступними: від найсучаснішого спорядження напрокат до порад експертів і привітної спільноти.
					</Text>
				</Box>
				<Image
					md-width="50%"
					sm-margin="0px 0px 24px 0px"
					align-self="flex-end"
					src="https://uploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-4.jpg?v=2024-06-12T07:08:52.451Z"
					object-fit="cover"
					width="45%"
					flex="0 0 auto"
					srcSet="https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-4.jpg?v=2024-06-12T07%3A08%3A52.451Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-4.jpg?v=2024-06-12T07%3A08%3A52.451Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-4.jpg?v=2024-06-12T07%3A08%3A52.451Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-4.jpg?v=2024-06-12T07%3A08%3A52.451Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-4.jpg?v=2024-06-12T07%3A08%3A52.451Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-4.jpg?v=2024-06-12T07%3A08%3A52.451Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-4.jpg?v=2024-06-12T07%3A08%3A52.451Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b35f058e2e8e002176cca3"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});